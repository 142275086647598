import * as React from 'react';
import {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import {Window} from "../window/Window";
import AuthService from "../../services/http/auth.service";

export default function LoginWindow(props: { active: boolean, setActive: (active: boolean) => void }) {
    const {active, setActive} = props;
    const [loginInProgress, setLoginInProgress] = useState(false);
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoginInProgress(true);
        const data = new FormData(event.currentTarget);
        const username = data.get('username') as string;
        const password = data.get('password') as string;
        AuthService.login({username, password})
            .then(
                res=>{window.location.reload()},
                err=>{console.log(err)}
            )
            .then(
                ()=>{
                    setLoginInProgress(false);
                    setActive(false);
                }
            )
    };

    return (
        <Window active={active} setActive={setActive}>
            <Paper>
                <Box
                    sx={{
                        margin: "20px",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: window.innerWidth > 700 ? "30vw" : "80 vw"
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        {
                            loginInProgress ?
                                <CircularProgress/>
                                :
                                <LockOutlinedIcon/>
                        }
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Вход
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Имя пользователя"
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Пароль"
                            type="password"
                            id="password"
                            autoComplete="password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Войти
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Window>
    );
}